<template>
	<div class="product_card_component">
		<!-- Temporary :style for mobile -->
		<ProductCard
			platform="Marketplace"
			:class="{
				'marketplace--editorial': plfm.isEditorial,
				'marketplace--default': !plfm.isEditorial,
			}"
			:style="customeSizeStyle"
			:images="getImages"
			:merchant="merchant?.name"
			:merchant-link="merchant?.url"
			:product-name="productName"
			:product-color="props.product.organization_textile_item_code"
			:product-material="props.product.textile?.name || ''"
			:inventory="props.product.inventory?.totalMeters ?? 0"
			:price-per-meter="props.product.pricing?.adjusted_price ?? '-'"
			:production-order="'-'"
			:custom-textile="'-'"
			:is-favorite="false"
			:cta-url="`/textiles/${props.product.textile.id}?item=${props.product.id}`"
			:title_font="plfm.secondaryFont"
			:text_font="plfm.mainFont"
			:link_color="plfm.colors.links"
			:btn-styles="plfm.btns"
			@view-product="() => onViewProductClick()"
			@image-clicked="() => onViewProductClick()"
		/>
	</div>
</template>
<script lang="ts" setup>
import type { Image, CatalogTextileItem } from "@/types/products";

const router = useRouter();

const { plfm, imgixEcmOdcUrl } = storeToRefs(usePlatformStore());

const props = defineProps<{
	product: CatalogTextileItem;
	size: string;
}>();

// Temp use of props.size for mobile style
const customeSizeStyle = computed(() => {
	if (plfm.value.isEditorial) {
		return props.size === "small" ? { width: "256px" } : {};
	}

	return props.size === "small" ? { width: "172px" } : {};
});

const productName = computed(() => {
	return `${props.product.textile?.organization_textile_code} (${props.product.textile?.organization_textile_name})`;
});

const getImages = computed(() => {
	return [...props.product.images_textiles_items].map((item: Image) => ({
		id: item.id,
		imageUrl: `${imgixEcmOdcUrl.value}/${item.image_path}?q=100&h=560&w=560&auto=compress`,
	}));
});

const merchant = computed(() => {
	const textile: any = props.product.textile;

	return {
		name: textile.organization_name,
		url: `/merchants/${textile.organization_id}`,
	};
});

const onViewProductClick = () => {
	router.push(`/textiles/${props.product.textile.id}?item=${props.product.id}`);
};
</script>
<style lang="scss" scoped>
// This is a little hack to make buttons smaller on active/default style
.product_card.marketplace--default {
	:deep(.v-btn--size-large) {
		--v-btn-size: 14px !important;
		--v-btn-height: 40px !important;

		.v-btn__content {
			@apply text-[14px] leading-[20px] font-[400] py-[10px];
		}
	}
}

// TODO When info is available, no longer hide the following :
:deep(.product_card.marketplace--default),
:deep(.product_card.marketplace--editorial) {
	@apply h-[595px];

	.product_info > .product_state > .production_order,
	.product_info > .product_state > .custom_textile {
		@apply hidden;
	}

	.product_info > .product_state > .product_price {
		@apply border-b-0 #{!important};
	}

	.image_wrapper > .icon_wrapper {
		@apply hidden;
	}
	.product_material {
		@apply min-h-[28px];
	}
	.v-slide-group__container {
		@apply min-h-[48px];
	}
	.image_wrapper {
		@apply bg-neutral-50;
	}
}

:deep(.product_card.marketplace--default) {
	@apply h-[508px];
}
</style>
